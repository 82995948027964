import Glide from '@glidejs/glide';
import smoothscroll from 'smoothscroll-polyfill';

new Glide('#gallery-slider').mount()
smoothscroll.polyfill();

document.querySelector('[href="#kontaktformular"]').addEventListener('click', (e) => {
  e.preventDefault();
  document
	  .getElementById("kontaktformular")
	  .scrollIntoView({behavior: "smooth"});
})

document.querySelector('#kontaktformular').addEventListener('submit', (e) => {
  e.currentTarget.classList.add('sent');
  gtag('event', 'conversion', {'send_to': 'AW-353988130/Vcr5COa5zL8CEKLc5agB'});
})